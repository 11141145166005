svg {width: 100%; height: auto;}
body, button, input, textarea{font-family: Poppins, 'Open Sans', sans-serif, system-ui;}

/* Light theme */
@media (not (prefers-color-scheme: dark)) {
  body {
    background-color: #FFFFFF;
    color: #333333;
  }
  header.site-header {
    color: #333333;
  }
  nav > button.nav-button {
    color: #333333;
  }
  nav > button.nav-button:hover{
    color: #4b636e;
    border-bottom: 4px solid #4b636e;
  }
  article {
    background-color: #dddee0ff;
    color: #000000;
  }
  form > button {
    background-color: #51A8DB;
  }
}

/* Dark theme*/
@media (prefers-color-scheme: dark) {
  body {
    background-color: #000000;
    color: #FFFFFF;
  }
  header.site-header {
    color: #FFFFFF;
  }
  nav > button.nav-bitton {
    color: #FFFFFF;
  }
  nav > button.nav-button:hover{
    color: #51A8DB;
    border-bottom: 4px solid #51A8DB;
  }
  article {
    background-color: #4b636e;
    color: #FFFFFF;
  }
  form > button {
    background-color: #51A8DB;
  }
}

@media screen and (max-width: 600px) {
  body {
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  body {
    max-width: 75%;
    position: absolute;
    left: 25%;
    right: 25%;
  }
}
@media screen and (min-width: 1024px) {
  body {
    max-width: 30%;
    position: absolute;
    left: 35%;
    right: 35%;
  }
}

body {
  margin-top: 0;
  padding-top: 0;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
}

header.site-header {
  text-align: center;
}

nav {
  width: 100%;
  text-align: center;
}

nav > button.nav-button {
  display: inline-block;
  width:30%;
  background-color: transparent;
  border: none;
  padding: 0.5em;
}

main {
  width: 100%
}
section {
  width: 100%;
}

article {
  text-align: center;
  border-radius: 5px;
}

form {
  margin-right: auto;
}

/* form input {
} */

form > label {
  display: inline-block;
  width: 25%;
  text-align: right;
  margin: 0.5em;
}

form > input, textarea {
  font: 1em sans-serif;
  width: 150px;
}

form > textarea {
  vertical-align: top;
}

form > button {
  float: right;
  position: relative;
  overflow: hidden;
  transition: background 400ms;
  color: #FFFFFF;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  outline: 0;
  border: 0;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  cursor: pointer;
}


/* footer {} */

/*
-- Primary (Blue Grey 400) --
Primary: #78909c
Text on Primary: #000000
Primary Light: #a7c0cd
Text on Primary Light: #000000
Primary Dark: #4b636e
Text on Primary Dark: #ffffff
-- Secondary (Blue 700) --
#Secondary: #789c96
Text on Secondary: #000000
Secondary Light: #a8cdc7
Text on Secondary Light: #000000
Secondary Dark: #4b6e68
Text on Secondary Dark: #ffffff
-- Misc --
Error: #B00020
Text on Error: #ffffff
*/